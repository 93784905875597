.messageBox {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-weight: 600;
  margin: auto;
  width: 300px;
  z-index: 100;
}

.messageBox__content {
  background-color: white;
  box-shadow: inset 0 0 10px 0 hsl(223, 22%, 20%);
  padding: 20px;
}

.messageBox__header {
  background: hsl(223, 22%, 20%);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: inset 0 0 2px 0 white;
  color: white;
  font-weight: 600;
  padding: 15px;
}

.messageBox__message {
  padding: 10px 5px 30px;
}

.messageBox__buttons {
  display: grid;
  justify-content: end;
}
