.tournamentList {
  display: grid;
  gap: 10px;
}

.tournamentList__actions {
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.tournamentList__select {
  font-weight: 600;
  width: 100%;
}

.tournamentList__selects {
  display: flex;
  gap: 10px;
}
