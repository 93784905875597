.contact {
  display: grid;
  gap: 20px;
  grid-column: span 2;
  justify-content: space-between;
}

.contact__item {
  align-items: center;
  display: flex;
  gap: 20px;
}

.contact__item:hover {
  animation: shake 1s cubic-bezier(0.5, 0.55, 0.55, 0.6) both;
}

.contact__line {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.05);
  margin: 10px 0;
}

@media (min-width: 800px) {
  .contact__item--wide {
    grid-column: span 3;
  }

  .contact__line {
    display: none;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-3px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(3px, 0, 0);
  }
}
