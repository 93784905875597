.groupCreate {
  display: grid;
  gap: 20px;
}

.groupCreate__addedUser {
  align-items: center;
  background: hsl(0, 59%, 41%);
  border: 1px solid black;
  display: flex;
  gap: 10px;
  padding: 5px;
}

.groupCreate__addedUsers {
  color: hsl(0, 0%, 100%);
  display: flex;
  flex-wrap: wrap;
  font-weight: 600;
  gap: 10px;
}

.groupCreate__addUsers {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
}

.groupCreate__form {
  display: grid;
  gap: 10px;
}

.groupCreate__removeButton {
  cursor: pointer;
  display: grid;
}
