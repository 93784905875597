.groupList {
  display: grid;
}

.groupList__actions {
  display: grid;
  margin-top: 10px;
}

.groupList__games {
  background-color: hsl(0, 0%, 87%);
  display: grid;
  gap: 1px;
  padding: 1px;
}

.groupList__header {
  font-weight: 600;
  margin: 20px 0 10px 0px;
}

.groupList__print {
  cursor: pointer;
  font-size: 1.4rem;
}

.groupList__select {
  font-weight: 600;
}

.groupList__selects {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, auto);
}
