.forgetPassword {
  display: grid;
  gap: 20px;
  max-width: 400px;
  padding: 10px;
}

.forgetPassword__form {
  display: grid;
  gap: 10px;
}
