html {
  box-sizing: border-box;
  font-size: 62.5%;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 1.4rem;
  height: 100vh;
  margin: 0;
  width: 100%;
}

* {
  box-sizing: inherit;
}

#root {
  display: grid;
  grid-template-rows: min-content min-content auto min-content;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input,
select,
textarea {
  border: 1px solid hsl(0, 0%, 75%);
  border-radius: 3px;
  font-size: 1.2rem;
  outline: none;
  padding: 5px;
}

@media (min-width: 800px) {
  body {
    font-size: 1.6rem;
  }

  input,
  select,
  textarea {
    font-size: 1.4rem;
    padding: 10px;
  }
}
