.tournamentCreate {
  display: grid;
  gap: 10px;
  padding: 10px;
}

.tournamentCreate__form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
