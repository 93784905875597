.rankingList {
  display: grid;
  gap: 10px;
}

.rankingList__info {
  border: 1px solid hsl(0, 0%, 0%);
  font-weight: 600;
  padding: 20px;
  text-align: center;
}

.rankingList__select {
  font-weight: 600;
}

.rankingList__sortable {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 2px;
  justify-content: center;
}
